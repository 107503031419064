'use client';

import { useEffect, type FunctionComponent } from 'react';
import { env } from '@/config';
import { withErrorBoundary } from 'react-error-boundary';

import { NoUiErrorFallback } from '@/components/ErrorFallback';
import loadGrapeshot from '@/components/Grapeshot';

const GrapeshotLoader: FunctionComponent = () => {
  useEffect(() => {
    loadGrapeshot(env.baseUrl);
  }, []);

  return null;
};

export default withErrorBoundary(GrapeshotLoader, {
  FallbackComponent: NoUiErrorFallback,
});
