import { env } from '@/config';
import jsonp from 'jsonp';

interface Channel {
  name: string;
}

export function loadGrapeshot(baseUrl: string): void {
  const { pathname } = window.location;
  const GRAPESHOT_URL = `${env.grapeshot.url}?url=${baseUrl}${pathname}`;

  jsonp(GRAPESHOT_URL, (err, data) => {
    // TODO: log err?
    if (!data || !data.channels || !!err) {
      return;
    }
    const channels = data.channels as Channel[];

    const channelNames = channels.map((channel) => channel.name);

    if (channelNames.length === 1 && channelNames[0] === 'RETRY') {
      return;
    }

    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(() => {
      window.googletag.pubads().setTargeting('gs_cat', channelNames);
    });
  });
}

export default loadGrapeshot;
